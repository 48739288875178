import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';

@Injectable({ providedIn: 'root' })
export class DownloadAppGTMService {
	private dataLayerSrv = inject(DataLayerService);

	click() {
		this.dataLayerSrv.push({
			eventName: 'download_app_click',
			event: 'downloadAppClick',
		});
	}
}
